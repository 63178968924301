import React from "react"
import MyLink from "../components/link"

export default function DreamProject() {
    let path = typeof window !== 'undefined' ? window.location.pathname : '';
    let exclude = [
        '/get-a-quote/'
    ];
    let show = !(exclude.indexOf(path) > -1);

    return (
        <div>
            {show &&
                <div className="component bg-blue clear-both">
                    <div className="container">
                        <h2 className="text-center mb-4">Are you ready to build your dream project?<span className="symbol symbol-dark"><span></span></span></h2>
                        <p className="text-center text-larger cl-dark">
                            Answer a few questions and you will receive an <strong>immediate budget range</strong> for your project.<br/>
                            We will review your requirements and respond with an official quote in <strong>1 business day</strong>.
                        </p>
                        <p className="text-center mt-12"><MyLink to="/get-a-quote/" className="btn btn-dark btn-lg">Let’s Get Started!</MyLink></p>
                    </div>
                </div>
            }
        </div>
    )
}